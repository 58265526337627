export default function TeamSection() {
	return (
		<section
			id="team"
			className="relative py-20 2xl:py-40 bg-gray-900 overflow-hidden"
		>
			<div className="relative container px-4 mx-auto">
				<div className="max-w-6xl mx-auto">
					<h2 className="mb-20 text-5xl font-bold font-heading text-white">
						Team
					</h2>
					<div className="flex flex-wrap -mx-5 mb-20">
						{/* ミヤタコーヘイ */}
						<div className="w-full lg:w-1/2 px-5 mb-10 lg:mb-0">
							<div className="bg-gray-800 overflow-hidden">
								<div className="px-12 pt-12 pb-4 text-right">
									<a
										className="inline-block mr-4"
										href="https://www.instagram.com/miyatako_hei/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											className="h-6"
											src="/images/icons/instagram-w.png"
											alt="instagram"
										/>
									</a>
									<a
										className="inline-block"
										href="https://www.tiktok.com/@miyata_ko_hei"
										target="_blank"
										rel="noreferrer"
									>
										<img
											className="h-6"
											src="/images/icons/tiktok-w.png"
											alt="tiktok"
										/>
									</a>
								</div>
								<div className="flex items-center mb-16">
									<img
										className="sm:w-48 sm:h-64 w-40 h-48 object-cover"
										src="/images/welcome/welcome-profile-miyata.jpg"
										alt="ミヤタコーヘイ"
									/>
									<div className="pl-8 pr-8">
										<h3 className="mb-2 text-2xl text-white font-bold font-heading">
											ミヤタコーヘイ
										</h3>
										<div className="mb-2">
											<p className="text-md text-white">音楽デザイナー</p>
											<p className="text-md text-white">Jazz Cellist</p>
										</div>
										<p className="text-md text-gray-300">
											2歳からピアノ、3歳でチェロをはじめ、幼少期より演奏活動を開始。
											ルールに縛られたクラシック音楽に嫌気がさし、クラシックの反逆者として、そして自由な音楽を求めて音楽デザイナー/即興音楽家として活動。
											音楽にまつわるすべてを再発明すべく、blackadderのプロデュースを担う。
										</p>
									</div>
								</div>
								<button className="w-full py-5 bg-blue-500 hover:bg-blue-600 text-white font-bold transition duration-200">
									<span>プロフィール</span>
									<span className="inline-block ml-4">
										<svg
											className="w-5 h-4"
											width={19}
											height={20}
											viewBox="0 0 19 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M18.7383 1.47293L18.7383 10.93L17.5562 10.93L17.5562 2.89739L0.834948 19.6245L0.00154682 18.7911L16.7228 2.064L9.28125 2.064L9.28125 0.881867L18.1472 0.881866C18.4737 0.881866 18.7383 1.14648 18.7383 1.47293Z"
												fill="white"
											/>
										</svg>
									</span>
								</button>
							</div>
						</div>

						{/* 小畑和彰 */}
						<div className="w-full lg:w-1/2 px-5">
							<div className="bg-gray-800 overflow-hidden">
								<div className="px-12 pt-12 pb-4 text-right">
									<a
										className="inline-block mr-4"
										href="https://www.instagram.com/butter251aug/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											className="h-6"
											src="/images/icons/instagram-w.png"
											alt="instagram"
										/>
									</a>
									<a
										className="inline-block"
										href="https://www.tiktok.com/@bataojisan"
										target="_blank"
										rel="noreferrer"
									>
										<img
											className="h-6"
											src="/images/icons/tiktok-w.png"
											alt="tiktok"
										/>
									</a>
								</div>
								<div className="flex items-center mb-16">
									<img
										className="sm:w-48 sm:h-64 w-40 h-48 object-cover"
										src="/images/welcome/welcome-profile-butter.jpg"
										alt="小畑和彰"
									/>
									<div className="pl-8 pr-8">
										<h3 className="mb-2 text-2xl text-white font-bold font-heading">
											小畑和彰
										</h3>
										<div className="mb-2">
											<p className="text-md text-white">Webエンジニア</p>
											<p className="text-md text-white">ピアニスト</p>
										</div>
										<p className="text-md text-gray-300">
											3歳からクラシックピアノをはじめ、現在は人生を豊かにするための趣味としてジャズ・ポップなどを中心に演奏活動をしている。
											本業はウェブエンジニア。blackadderのWebサービス開発のすべてを担う。
										</p>
									</div>
								</div>
								<button className="w-full py-5 bg-blue-500 hover:bg-blue-600 text-white font-bold transition duration-200">
									<span>プロフィール</span>
									<span className="inline-block ml-4">
										<svg
											className="w-5 h-4"
											width={19}
											height={20}
											viewBox="0 0 19 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M18.7383 1.47293L18.7383 10.93L17.5562 10.93L17.5562 2.89739L0.834948 19.6245L0.00154682 18.7911L16.7228 2.064L9.28125 2.064L9.28125 0.881867L18.1472 0.881866C18.4737 0.881866 18.7383 1.14648 18.7383 1.47293Z"
												fill="white"
											/>
										</svg>
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
