export default function AboutSection() {
	return (
		<section id="about" className="py-24 md:py-40 bg-black">
			<div className="container mx-auto px-4">
				<div className="max-w-6xl mx-auto">
					<h2 className="mb-20 text-5xl font-bold font-heading text-white">
						About
					</h2>
				</div>
				<div className="max-w-6xl mx-auto">
					<div
						className="mb-20 relative overflow-hidden"
						style={{ height: 512 }}
					>
						<img
							className="absolute top-0 left-0 w-full h-full object-cover transform hover:scale-105 transition duration-200"
							src="/images/welcome/welcome-about-01.jpg"
							alt="About Image"
						/>
					</div>
					<div className="mb-24 text-base md:text-xl max-w-5xl mx-auto text-center text-slate-200 font-light leading-relaxed">
						<p className="mb-2">
							音楽にまつわるすべてを再発明することをテーマに、私たちはWebと音楽の融合によって新たな価値を創出します。
						</p>
						<p className="mb-2">
							音楽を愛するすべての人々に向けて、最新のテクノロジーと革新的なサービスを提供し、音楽を次の次元へと導きます。
						</p>
						<p className="mb-2">
							私たちは、音楽の持つ力を最大限に引き出し、アーティスト、ファン、そして全ての音楽愛好家が一体となるプラットフォームを構築しています。
						</p>
						<p className="">
							創造性と情熱が交差するこの場所で、音楽の未来を共に描き出しましょう。
						</p>
					</div>
					<div className="flex flex-wrap items-center">
						<div className="w-full md:w-1/2 p-10">
							<div className="relative overflow-hidden" style={{ height: 496 }}>
								<img
									className="absolute top-0 left-0 w-full h-full object-cover transform hover:scale-105 transition duration-200"
									src="/images/welcome/welcome-about-02.jpg"
									alt="About Image2"
								/>
							</div>
						</div>
						<div className="w-full md:w-1/2 p-10 text-white">
							<h3 className="mb-16 ont-heading text-4xl md:text-5xl font-bold leading-tight">
								Mission
							</h3>
							<ul>
								<li className="flex items-center -m-2 mb-2">
									<div className="w-auto p-2">
										<div className="flex items-center justify-center w-7 h-7 bg-gray-50 rounded-full">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={12}
												height={12}
												viewBox="0 0 12 12"
												fill="none"
											>
												<path
													d="M3.87459 10.3313C3.53396 10.3315 3.20728 10.1961 2.96662 9.95501L0.22153 7.21093C-0.0738435 6.91546 -0.0738435 6.43651 0.22153 6.14104C0.516999 5.84567 0.995953 5.84567 1.29142 6.14104L3.87459 8.72422L10.7086 1.89023C11.004 1.59486 11.483 1.59486 11.7785 1.89023C12.0738 2.1857 12.0738 2.66465 11.7785 2.96012L4.78256 9.95501C4.5419 10.1961 4.21523 10.3315 3.87459 10.3313Z"
													fill="#273ACF"
												/>
											</svg>
										</div>
									</div>
									<div className="w-auto p-2">
										<span className="text-lg font-medium">
											すべての音楽家に寄り添ったWebサービスを
										</span>
									</div>
								</li>

								<li className="flex items-center -m-2 mb-2">
									<div className="w-auto p-2">
										<div className="flex items-center justify-center w-7 h-7 bg-gray-50 rounded-full">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={12}
												height={12}
												viewBox="0 0 12 12"
												fill="none"
											>
												<path
													d="M3.87459 10.3313C3.53396 10.3315 3.20728 10.1961 2.96662 9.95501L0.22153 7.21093C-0.0738435 6.91546 -0.0738435 6.43651 0.22153 6.14104C0.516999 5.84567 0.995953 5.84567 1.29142 6.14104L3.87459 8.72422L10.7086 1.89023C11.004 1.59486 11.483 1.59486 11.7785 1.89023C12.0738 2.1857 12.0738 2.66465 11.7785 2.96012L4.78256 9.95501C4.5419 10.1961 4.21523 10.3315 3.87459 10.3313Z"
													fill="#273ACF"
												/>
											</svg>
										</div>
									</div>
									<div className="w-auto p-2">
										<span className="text-lg font-medium">
											革新的なサービスをリーズナブルな料金で
										</span>
									</div>
								</li>

								<li className="flex items-center -m-2 mb-2">
									<div className="w-auto p-2">
										<div className="flex items-center justify-center w-7 h-7 bg-gray-50 rounded-full">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={12}
												height={12}
												viewBox="0 0 12 12"
												fill="none"
											>
												<path
													d="M3.87459 10.3313C3.53396 10.3315 3.20728 10.1961 2.96662 9.95501L0.22153 7.21093C-0.0738435 6.91546 -0.0738435 6.43651 0.22153 6.14104C0.516999 5.84567 0.995953 5.84567 1.29142 6.14104L3.87459 8.72422L10.7086 1.89023C11.004 1.59486 11.483 1.59486 11.7785 1.89023C12.0738 2.1857 12.0738 2.66465 11.7785 2.96012L4.78256 9.95501C4.5419 10.1961 4.21523 10.3315 3.87459 10.3313Z"
													fill="#273ACF"
												/>
											</svg>
										</div>
									</div>
									<div className="w-auto p-2">
										<span className="text-lg font-medium">
											あらゆる操作をかんたんに
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
