import HeroSection from "../components/sections/HeroSection";
import TeamSection from "../components/sections/TeamSection";
import AboutSection from "../components/sections/AboutSection";
import CompanySection from "../components/sections/CompanySection";

export default function Home() {
	return (
		<div className="min-h-screen flex flex-col justify-center">
			{/* ヒーローセクション */}
			<HeroSection />

			{/* チームセクション */}
			<TeamSection />

			{/* アバウトセクション */}
			<AboutSection />

			{/* 会社情報セクション */}
			<CompanySection />
		</div>
	);
}
