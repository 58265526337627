export default function HeroSection() {
	return (
		<section className="h-screen bg-black relative flex items-center justify-center">
			<div className="z-10 relative container px-4 pt-[50dvh] md:pt-[50dvh] mx-auto">
				<div className="flex flex-wrap items-center -mx-4">
					<div className="w-full lg:w-1/2 px-4">
						<span className="text-lg font-bold text-teal-400">
							Established in 2024
						</span>
						<h2 className="max-w-2xl mt-12 mb-12 text-5xl md:text-6xl 2xl:text-8xl text-white font-bold font-heading">
							Reinvent everything about music.
						</h2>
						<p className="mb-12 lg:mb-16 2xl:mb-24 text-md text-gray-100">
							[ 音楽にまつわるすべてを再発明する ]
						</p>
					</div>
				</div>
			</div>
			<div className="absolute z-0 inset-0">
				<img
					className="h-screen w-screen object-cover opacity-80"
					src="/images/welcome/welcome-bg.jpg"
					alt="background"
				/>
			</div>
		</section>
	);
}
