export default function CompanySection() {
	return (
		<section id="company" className="py-20 bg-gray-900">
			<div className="container px-4 mx-auto text-white">
				<div className="max-w-6xl mx-auto">
					<h2 className="mb-10 text-5xl font-bold font-heading">Company</h2>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">会社名</h3>
						<p>合同会社blackadder</p>
					</div>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">代表</h3>
						<p>宮田浩平 / 小畑和彰（共同代表）</p>
					</div>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">法人番号</h3>
						<p>3011003019957</p>
					</div>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">所在地</h3>
						<p>〒150-0002 東京都渋谷区渋谷2-19-15宮益坂ビルディング609</p>
					</div>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">設立</h3>
						<p>2024年9月12日</p>
					</div>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">資本金</h3>
						<p>5,000,000円</p>
					</div>
					<div className="mb-6">
						<h3 className="text-2xl font-semibold mb-2">事業内容</h3>
						<ul className="list-disc list-inside text-gray-200 leading-relaxed">
							<li>
								コンピュータのソフトウェア及びハードウェアの企画、研究、開発、設計、製造、販売、保守、リース、賃貸及び輸出入並びにそれらに関するコンサルティング業務
							</li>
							<li>音声、映像のソフトウェアの企画、制作、販売及び賃貸</li>
							<li>
								ＥＣ（電子商取引）サイト、その他各種ウェブサイトの企画、制作、販売、配信、運営及び管理
							</li>
							<li>各種イベントの企画及び運営の請負</li>
							<li>
								映画、コンサート、演劇、スポーツ、イベント等の各種催物チケットの販売
							</li>
							<li>
								映画、音楽、美術、演劇、演芸、講演、スポーツ事業その他の文化事業の企画、制作、興行、それらの施設の運営及び請負並びにそれらの関連商品の販売
							</li>
							<li>楽譜の出版、販売及び輸出入</li>
							<li>作詞、作曲、編曲及び写譜の受託</li>
							<li>前各号に附帯関連する一切の事業</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}
